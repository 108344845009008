import { Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import TextFieldCustom from "../../../components/TextFieldCustom/TextFieldCustom";
import CalendarRange from "../../../components/CalendarRange/CalendarRange";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import MultipleSelectCheckbox from "../../../components/MultipleSelectCheckbox";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import {
  convertToOptionsList,
  getFirstDayOfCurrentMonth,
} from "../../../utils";
import { GetAllNfeQuery } from "../../../modules/financial/models/GetAllNfeQuery";
import { ROW_PER_PAGE } from "../../ConsultQuotaOrders/models/SearchFilterOptions";
import { useOrderQuote } from "../../../contexts/orderQuote/OrderQuoteContext";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";
import { convertToISO } from "../../../utils/dateFormatter";

interface SelectItem {
  value: string;
  label: string;
}

interface InvoiceFilterTableProps {
  onButtonChange: (query: any) => void;
  onButtonClearFilter: (isClear: boolean) => void;
}

const InvoicesFilter: React.FC<InvoiceFilterTableProps> = ({
  onButtonChange,
  onButtonClearFilter,
}) => {
  const { getProducts } = useOrderQuote();
  const { selectedDistributor, distributors } = useDistributor();

  const [codeProducts, setCodeProducts] = useState<string[] | string>([]);
  const [materialNumberStorage] = useState("");
  const [products, setProducts] = useState<SelectItem[]>([]);
  const [clearValueCalendar, setClearValueCalendar] = useState<boolean>(false);
  const [searchQueryNote, setSearchQueryNote] = useState<string>("");
  const [firstLoading, setFirstLoading] = useState(true);
  const [distributorsMapped, setDistributorsMapped] = useState<SelectItem[]>(
    []
  );
  const [codeDistributors, setCodeDistributors] = useState<string[]>([]);
  const storageExternalId = selectedDistributor?.externalId;

  const currentDay = new Date();
  const firstDay = new Date(currentDay.getFullYear(), currentDay.getMonth(), 1);

  const initFilters: GetAllNfeQuery = {
    orderBy: "creationDate",
    orderDirection: "desc",
    service: "NFE",
    customerNumber: storageExternalId,
    documentDateBegin: firstDay.toISOString(),
    documentDateEnd: currentDay.toISOString(),
    id: materialNumberStorage || "",
    page: 1,
    limit: ROW_PER_PAGE,
    electronicInvoiceNumber: searchQueryNote || "",
  };
  const [filters, setFilters] = useState<GetAllNfeQuery | null>(initFilters);

  const handleChangeSelectedCompanies = useCallback(
    (event: string[]) => {
      if (event?.length > 0) {
        const selectedProducts = event?.join(",");
        setCodeProducts(event);
        setFilters((oldState) => ({
          ...oldState,
          id: selectedProducts,
        }));
      } else {
        if (!firstLoading) {
          setCodeProducts(event);
          onButtonClearFilter(true);
        }
        if (filters) {
          setFilters((oldState) => ({
            ...oldState,
            id: "",
          }));
        }
      }
    },
    [setCodeProducts, firstLoading, setFilters]
  );

  const handleChangeSelectedDistributors = useCallback(
    (event: string[]) => {
      if (event?.length > 0) {
        const selectedItem = event?.join(",");
        setCodeDistributors(event);
        setFilters((oldState) => ({
          ...oldState,
          customerNumber: selectedItem,
        }));
      } else {
        if (!firstLoading) {
          setCodeDistributors(event);
          onButtonClearFilter(true);
        }
        if (filters) {
          setFilters((oldState) => ({
            ...oldState,
            customerNumber: "",
          }));
        }
      }
    },
    [setCodeDistributors, firstLoading, setFilters]
  );

  const handleSearchChangeNote = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement> | any) => {
      if (event?.target) {
        const data = event.target.value.trim();

        if (data !== searchQueryNote) {
          setSearchQueryNote(data);
        }
        setFilters((oldState) => ({
          ...oldState,
          electronicInvoiceNumber: data,
        }));
      }
    },
    [setSearchQueryNote, searchQueryNote, setFilters]
  );

  const clearFilters = useCallback(() => {
    setClearValueCalendar(true);
    setSearchQueryNote("");
    setCodeProducts(products.map((ele) => ele.value));
    setCodeDistributors(distributorsMapped.map((ele) => ele.value));
    setFilters((oldState) => ({
      ...initFilters,
      electronicInvoiceNumber: "",
      id: products.map((ele) => ele.value).join(","),
    }));

    onButtonChange({
      ...initFilters,
      electronicInvoiceNumber: "",
      id: products.map((ele) => ele.value).join(","),
    });
    onButtonClearFilter(true);
  }, [
    setClearValueCalendar,
    setSearchQueryNote,
    onButtonClearFilter,
    onButtonChange,
    setCodeProducts,
    setCodeDistributors,
    setFilters,
    products,
    initFilters,
    filters,
  ]);

  const getProductsData = useCallback(async () => {
    const response = await getProducts?.();
    if (response === undefined) return;
    const productsMapped: SelectItem[] = response?.map((product: any) => ({
      value: product.externalId,
      label: product.description,
    }));

    if (response?.length) setProducts(productsMapped);
    setCodeProducts(productsMapped.map((ele) => ele.value));
  }, [getProducts, setProducts, setCodeProducts]);

  const getDistributorsData = useCallback(async () => {
    if (distributors?.length === 0) return;
    const distributorsMapped: SelectItem[] = distributors?.map(
      (product: any) => ({
        value: product.externalId,
        label: product.description,
      })
    );

    const itensCode = distributorsMapped.map((ele) => ele.value);
    setDistributorsMapped(distributorsMapped);
    setCodeDistributors(itensCode);

    setFilters((oldState) => ({
      ...oldState,
      customerNumber: itensCode?.join(","),
      ...(!oldState?.documentDateBegin && {}),
    }));
  }, [setDistributorsMapped, setCodeDistributors, distributors]);

  useEffect(() => {
    getProductsData();
  }, []);

  useEffect(() => {
    getDistributorsData();
  }, [distributors]);

  useEffect(() => {
    if (firstLoading && filters && codeDistributors.length) {
      onButtonClearFilter(false);
      onButtonChange(filters);
      setFirstLoading(false);
    }
  }, [firstLoading, filters, codeDistributors]);

  useEffect(() => {
    if (storageExternalId) {
      setFirstLoading(true);
    }
  }, [storageExternalId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={6} md={3}>
            <MultipleSelectCheckbox
              maxWidth={1200}
              minWidth={100}
              label="Selecione o cliente"
              options={convertToOptionsList(
                distributorsMapped,
                "value",
                "label"
              )}
              value={codeDistributors}
              onChange={handleChangeSelectedDistributors}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={3}>
            <Typography
              sx={{
                color: "#58595B",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "700",
                paddingBottom: "4px",
              }}
            >
              Nota Fiscal
            </Typography>
            <TextFieldCustom
              type="number"
              inputProps={{
                min: 0,
              }}
              placeholder="Busque pelo Número da nota"
              value={searchQueryNote}
              onChange={handleSearchChangeNote}
              endComponent={<StyledIcon iconType="search" />}
              isDisabled={!codeDistributors.length || codeProducts.length === 0}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={3}>
            <MultipleSelectCheckbox
              maxWidth={1200}
              minWidth={100}
              label="Produtos"
              isDisabled={!codeDistributors.length}
              options={convertToOptionsList(products, "value", "label")}
              value={codeProducts as string[]}
              onChange={handleChangeSelectedCompanies}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={3}>
            <CalendarRange
              name="date"
              label="Período de Emissão"
              firstDayOfMonth={getFirstDayOfCurrentMonth()}
              lastDayOfMonth={currentDay}
              value={[
                filters?.documentDateBegin
                  ? new Date(filters?.documentDateBegin)
                  : firstDay,
                filters?.documentDateEnd
                  ? new Date(filters?.documentDateEnd)
                  : currentDay,
              ]}
              clearValueCalendar={clearValueCalendar}
              disabledTextField={
                !codeDistributors.length || codeProducts.length === 0
              }
              onChangeDateStartEnd={(dateBegin, dateEnd) => {
                setClearValueCalendar(false);
                if (
                  dateBegin !== null &&
                  dateEnd !== null &&
                  dateBegin.getTime() === dateEnd.getTime()
                ) {
                  setFilters((oldState) => ({
                    ...oldState,
                    documentDateBegin: convertToISO(dateBegin),
                    documentDateEnd: convertToISO(dateEnd),
                  }));
                } else {
                  if (dateBegin !== null && dateEnd !== null) {
                    setFilters((oldState) => ({
                      ...oldState,
                      documentDateBegin: convertToISO(dateBegin),
                      documentDateEnd: convertToISO(dateEnd),
                    }));
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={3}
        container
        alignItems="flex-end"
        justifyContent={"flex-end"}
        alignContent={"flex-start"}
        marginTop={"25px"}
      >
        <CustomButton
          title="Limpar filtros"
          variant={CustomButtonVariant.OUTLINED}
          onClick={clearFilters}
        />

        <CustomButton
          sx={{ marginLeft: "16px" }}
          type="submit"
          title="Consultar"
          variant={
            codeProducts.length === 0
              ? CustomButtonVariant.DISABLED
              : CustomButtonVariant.CONTAINED
          }
          disabled={codeProducts.length === 0}
          onClick={() => {
            onButtonClearFilter(false);
            onButtonChange(filters);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default InvoicesFilter;
// eslint-disable-next-line prettier/prettier
