const currentDomain = window.location.hostname.replace(/^\w+\./, "");
const cookieHost = (
  window.location.hostname === "localhost" ? "localhost" : `.${currentDomain}`
).replace(/\.+/g, "");
const currentLocation = window.location.origin;

export const isDevelopment =
  process.env.REACT_APP_ENVIRONMENT === "development" ||
  window.location.hostname === "localhost" ||
  Boolean(window.location.origin.split(".")[0].match(/-dev$/));

function generateDevUrl(path: string) {
  return isDevelopment ? `${path}-dev` : path;
}

const config = {
  api: {
    url: `https://${generateDevUrl(
      "mw"
    )}.ream.com.br/mw-canal-cliente-ream/rest`,
    timeout: 30000,
  },
  auth: {
    url: `https://${generateDevUrl("mw")}.ream.com.br/mw-auth`,
    timeout: 30000,
  },
  host: cookieHost,
  urlLogout: `https://${generateDevUrl(
    "auth"
  )}.ream.com.br/#/logout?url=${currentLocation}&rules=canal_cliente_ream`,
  urlLogoutDev: `http://localhost:3001/#/logout?url=${currentLocation}&rules=canal_cliente_ream`,
};

export default config;
