import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CardDataNotFound from "../../components/CardDataNotFound/CardDataNotFound";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import { CustomButtonVariant } from "../../components/CustomButton/CustomButton";
import CustomCard from "../../components/CustomCard/CustomCard";
import Layout from "../../components/Layout/Layout";
import ModalDetailsContent from "../../components/ModalDetailsNoteContent/ModalDetailsNoteContent";
import ModalExtract from "../../components/ModalExtract/ModalExtract";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import StatusTag from "../../components/StatusTag/StatusTag";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import TablePaginated from "../../components/TablePaginated/TablePaginated";
import { useDistributor } from "../../contexts/distributor/DistributorContext";
import { useIocContext } from "../../contexts/ioc/IocContext";
import useDialogAlert from "../../hooks/DialogAlert";
import { Types } from "../../ioc/types";
import { DownloadNfeQuery } from "../../modules/financial/models/DownloadNfeQuery";
import { GetAllNfeQuery } from "../../modules/financial/models/GetAllNfeQuery";
import { IFinancialService } from "../../modules/financial/models/IFinancialService";
import {
  calcPagesQuantity,
  getThousand,
  getTypeTagCancelledNote,
  isCancelled,
} from "../../utils";
import AppError from "../../utils/appError";
import { formatCurrency } from "../../utils/currencyFormatter";
import { convertToDefaultDate } from "../../utils/dateFormatter";
import { downloadExcel, downloadThisWindow } from "../../utils/download";
import {
  STATUS_NOTE_CANCELLED,
  STATUS_NOTE_SENT,
} from "../../utils/enumStatusNote";
import InvoicesFilter from "./Sections/InvoicesFilter";
import { DownloadList, DownloadType } from "./models/Download";
import { TableHeaderColumns } from "./models/TableHeaderColumns";

const ROW_PER_PAGE = 10;
interface TableRow {
  id: string;
  noteNumber: string;
  product: string;
  dateOfIssue: string;
  dueDate: string;
  quantity: string;
  price: string;
  accessKey: string;
  isLoadingDownloadNfe?: boolean;
  CFOP: string;
  operationNature: string;
  unitOfMeasure?: string;
  cancelled: React.ReactNode;
  isCancelled: boolean;
}

const ConsultInvoicesPage: React.FC = () => {
  const [data, setData] = useState<TableRow[]>([]);
  const [errorData, setErrorData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [total, setTotal] = useState(0);
  const { snackbar } = useDialogAlert();
  const { selectedDistributor } = useDistributor();

  const [filter, setFilter] = useState<GetAllNfeQuery | null>(null);
  const [isClear, setClear] = useState<boolean>(false);
  const storageExternalId = selectedDistributor?.externalId;
  const isClickedInFilterStorage = localStorage.getItem("isClickedInFilter");

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dataModalDetail, setDataModalDetail] = useState<TableRow>();

  const { serviceContainer } = useIocContext();

  const financialService = serviceContainer.get<IFinancialService>(
    Types.Financial.IFinancialService
  );

  const isView = useMemo(() => {
    return (
      (filter && !errorData && !isClear) || (filter && !errorData && isClear)
    );
  }, [filter, errorData, isClear]);

  const getAllNfe = useCallback(async () => {
    try {
      if (!filter) return;
      setErrorData(false);
      setIsLoadingData(true);
      const response = await financialService.getAllNfe(filter);
      const newData = response.content.map((item, index) => ({
        id: `${index}-${item.CNPJ}`,
        noteNumber: item.electronicInvoiceNumber,
        product: item.materialDescription,
        dateOfIssue: convertToDefaultDate(item.creationDate),
        dueDate: convertToDefaultDate(item.dueDate),
        quantity: getThousand(item.quantity),
        unit: item.unitOfMeasure,
        price: formatCurrency(item.invoiceTotal),
        accessKey: item.accessKey,
        CFOP: item.CFOP,
        operationNature: item.operationNature,
        unitOfMeasure: item.unitOfMeasure,
        cancelled: (
          <StatusTag
            text={isCancelled(item.cancelled)}
            type={getTypeTagCancelledNote(item.cancelled)}
            marginLeft={"10px"}
          />
        ),
        isCancelled: item.cancelled,
      }));

      if (response.content.length === 0) {
        setErrorData(true);
      }
      setTotal(response.total);
      setData(newData);
    } catch (error) {
      snackbar({
        message: "Erro ao carregar notas fiscais. Tentar novamente",
        variant: "error",
      });
      setErrorData(true);
    } finally {
      setIsLoadingData(false);
    }
  }, [filter]);

  const fetchDownlaod = async (query: DownloadNfeQuery, index: number) => {
    try {
      const response = await financialService.downloadNfeReport(query);
      switch (query.type) {
        case "pdf":
          downloadThisWindow(response, "pdf", query);
          break;
        case "xml":
          downloadThisWindow(response, "xml", query);
          break;
      }
      snackbar({
        message: "Download feito com sucesso",
        variant: "success",
      });
    } catch (error) {
      if ((error as AppError).statusCode === 404) {
        snackbar({
          message: "Nota fiscal não encontrada",
          variant: "error",
        });
      } else {
        snackbar({
          message: "Erro ao fazer download. Tentar novamente",
          variant: "error",
        });
      }
    }
  };

  const downloadReport = useCallback(async () => {
    try {
      if (!filter) return;
      setIsLoadingDownload(true);
      const response = await financialService.downloadReportNfe({
        service: "NFE",
        ...filter,
      });
      downloadExcel(response);
      snackbar({
        message: "Download feito com sucesso",
        variant: "success",
      });
    } catch (error) {
      snackbar({
        message: "Erro ao fazer download. Tentar novamente",
        variant: "error",
      });
    } finally {
      setIsLoadingDownload(false);
    }
  }, [filter]);

  useEffect(() => {
    if (filter) {
      getAllNfe();
    }
  }, [filter]);

  interface StyledMenuProps {
    index: number;
    accessKey: string;
    item: TableRow;
  }

  const StyledMenu: React.FC<StyledMenuProps> = ({
    index,
    accessKey,
    item,
  }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const updateDate = (isLoading: boolean) => {
      const newData = [...data];
      newData[index].isLoadingDownloadNfe = isLoading;
      setData(newData);
    };

    const handleMenuItemClick = async (type: DownloadType) => {
      setAnchorEl(null);
      switch (type) {
        case "pdf":
        case "xml":
          updateDate(true);
          await fetchDownlaod(
            {
              type,
              codes: [accessKey],
            },
            index
          );
          updateDate(false);
          break;
        case "tax":
          break;
        case "detailNote":
          setDataModalDetail(item);
          setOpenDialog(true);

          break;
        default:
          break;
      }
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton onClick={handleClickListItem}>
          <StyledIcon iconType="three-vertical-points" />
        </IconButton>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
          sx={{
            minWidth: 300,
          }}
        >
          {DownloadList.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuItemClick(option.type);
              }}
              sx={{
                minWidth: 200,
                fontSize: 14,
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "shadesOfDark.dark",
                  backgroundColor: "shadesOfDark.ultraLight",
                  fontWeight: 700,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "shadesOfDark.ultraLight",
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const LoadDataOnModal: React.FC<{ row?: TableRow; openDialog?: boolean }> = (
    props
  ) => {
    const handleOpenClose = (statusModal: boolean) => {
      setOpenDialog(statusModal);
    };

    const componentContentDetailsNote = (
      <ModalDetailsContent
        icon={
          <StyledIcon
            iconType="data-not-found"
            sx={{
              width: "8rem",
              height: "8rem",
            }}
          />
        }
        noteNumber={{
          title: `Número da nota`,
          value: props.row?.noteNumber ?? "",
        }}
        statusNote={{
          title: "",
          value: props.row?.isCancelled
            ? STATUS_NOTE_CANCELLED
            : STATUS_NOTE_SENT,
        }}
        quantity={{
          title: "Quantidade",
          value: `${props.row?.quantity ? props.row?.quantity : ""}${" "}${
            props.row?.unitOfMeasure ? props.row?.unitOfMeasure : ""
          }`,
        }}
        invoiceTotal={{ title: "Valor", value: props.row?.price ?? "" }}
        documentDate={{
          title: "Data de emissão",
          value: props.row?.dateOfIssue ?? "",
        }}
        dueDate={{
          title: "Data de vencimento",
          value: props.row?.dueDate ?? "",
        }}
        CFOP={{
          title: "CFOP",
          value: props.row?.CFOP ?? "",
        }}
        operationNature={{
          title: "Natureza da operação",
          value: props.row?.operationNature ?? "",
        }}
      />
    );

    return (
      <ModalExtract
        openDialog={openDialog}
        handleClose={() => handleOpenClose(false)}
        componentItems={componentContentDetailsNote}
        titleModal={"Detalhes da Nota"}
        buttons={[
          {
            title: "Fechar",
            variant: CustomButtonVariant.CONTAINED,
            onClick: () => handleOpenClose(false),
          },
        ]}
      />
    );
  };

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid>
        <LoadDataOnModal row={dataModalDetail} openDialog={openDialog} />
      </Grid>

      <Grid item xs={12}>
        <QueryPageTitleCard title="Consultar NFE" />
      </Grid>

      <Grid item xs={12} sx={{ marginTop: "24px" }}>
        <CustomCard
          sx={{
            boxShadow: "none",
            border: "1px solid #DFE0EB",
            height: "100%",
            padding: 2.4,
          }}
        >
          <CardTitleWithSubtitle
            title="Consultar Nota Fiscal"
            subtitle="Informe os parâmetros de pesquisa para consultar a nota fiscal. As informações estão disponíveis para um período máximo de 5 anos."
            buttonProps={{
              title: "Baixar relatório",
              variant: isLoadingDownload
                ? CustomButtonVariant.CONTAINED_DOWNLOAD_LOADING
                : CustomButtonVariant.CONTAINED_DOWNLOAD,
              onClick: () => {
                if (!filter) {
                  snackbar({
                    message: "Selecione um cliente para baixar relatório.",
                    variant: "warning",
                  });
                } else {
                  downloadReport();
                }
              },
            }}
          />

          <Grid container>
            <InvoicesFilter
              onButtonChange={(value) => {
                setFilter(value);
              }}
              onButtonClearFilter={(value) => {
                setClear(value);
              }}
            />

            <Grid item xs={12} mt={2}>
              {!filter?.id &&
              isClear &&
              !storageExternalId &&
              !isClickedInFilterStorage ? (
                <Box alignSelf="center">
                  <CardDataNotFound
                    icon="search-empty"
                    title="Selecione um ou mais produtos para começar"
                    subtitle="Informe outros critérios de busca e comece a consultar!"
                  />
                </Box>
              ) : !errorData && isView ? (
                <TablePaginated
                  columns={TableHeaderColumns}
                  data={data.map((item, index) => ({
                    ...item,
                    icon: item.isLoadingDownloadNfe ? (
                      <CircularProgress size={16} color="secondary" />
                    ) : (
                      <StyledMenu
                        index={index}
                        accessKey={item.accessKey}
                        item={item}
                      />
                    ),
                  }))}
                  rowsPerPage={ROW_PER_PAGE}
                  isLoadingData={isLoadingData}
                  loadFromServer
                  onChangePage={(newPage) => {
                    setFilter((oldState: any) => ({
                      ...oldState,
                      page: newPage,
                    }));
                  }}
                  paginationProps={{
                    pagesTotal: calcPagesQuantity(ROW_PER_PAGE, total),
                    // page: filter && filter.page,
                  }}
                  resultsTotal={total}
                />
              ) : (
                <Box alignSelf="center">
                  <CardDataNotFound
                    icon="cactus"
                    title="Não foram encontradas notas fiscais"
                    subtitle="Informe outros critérios de busca e consulte novamente."
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>
    </Layout>
  );
};

export default ConsultInvoicesPage;
