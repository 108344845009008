import { format, subMonths, parseISO, add, subDays } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

type DatesType = Record<string, string>;
const MONTH_MAX = 5;
export const dates: DatesType = {
  "01": "Janeiro",
  "02": "Fevereiro",
  "03": "Março",
  "04": "Abril",
  "05": "Maio",
  "06": "Junho",
  "07": "Julho",
  "08": "Agosto",
  "09": "Setembro",
  "10": "Outubro",
  "11": "Novembro",
  "12": "Dezembro",
};

export function formatDataForExtension(date: string) {
  const dateSplit = date.split("-");
  const year = dateSplit[0];
  const month = dateSplit[1];

  return `${dates[month]}/${year}`;
}

export function DateToFormat(date?: string | number | Date) {
  if (date === undefined) return "Data inválida";
  const dateUTC = zonedTimeToUtc(date, "America/Manaus");

  return format(dateUTC, "dd/MM/yyyy");
}

export function DateAndHoursToFormat(dateAndHours?: string) {
  if (dateAndHours === undefined) return "Data inválida";
  const date = DateToFormat(dateAndHours);
  const hours = getFormattedHour(new Date(dateAndHours));
  return `${date.toString()} às ${hours}h`;
}

export const replaceDashWithSlash = (dateString?: string) =>
  dateString ? dateString.replace(/-/g, "/") : "";

export function getFormattedHour(date?: Date) {
  if (date === undefined) return "Horário inválido";

  return format(date, "HH:mm");
}

export function ISODateToFormat(date: string) {
  if (/^[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}.[\d]{3}Z$/.test(date)) {
    const dateUTC = new Date(date);
    dateUTC.setHours(dateUTC.getHours() + 8);
    return format(dateUTC, "dd/MM/yyyy");
  }

  return "-";
}

export function convertToISO(dateString: Date): string {
  const date = new Date(dateString);
  return date.toISOString();
}

export function getCurrentDateMinusThirtyDays(): string {
  const currentDate = new Date();
  const thirtyDaysAgo = subDays(currentDate, 30);
  return convertToISO(thirtyDaysAgo);
}

export function formatDateWithDrash(date: Date): string {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "";
  }

  const formattedDate = format(date, "dd-MM-yyyy");

  return formattedDate;
}

export function replaceTraceByBar(date: string): string {
  const inputDate = new Date(date);
  const day = inputDate.getDate().toString().padStart(2, "0");
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear().toString();
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

export function convertToUTC(dateString: Date): string {
  const date = new Date(dateString);
  date.setUTCHours(0, 0, 0, 0);
  const isoString = date.toISOString();
  return isoString;
}

export function getDateNowWithISO() {
  return new Date().toDateString();
}

export function getCurrentDateISO() {
  return new Date().toISOString();
}

export function getLastMonthWithISO() {
  return subMonths(new Date(), 1).toISOString();
}

export function dateRangeFormat(
  startDate?: Date | string | null,
  endDate?: Date | string | null
) {
  if (startDate === null && endDate === null) {
    return "";
  }
  const startDateFormat: string =
    startDate instanceof Date ? format(startDate, "dd/MM/yyyy") : "";

  const endDateFormat: string =
    endDate instanceof Date ? format(endDate, "dd/MM/yyyy") : "";

  return `${startDateFormat} a ${endDateFormat}`;
}

export function getMonthName(dateString: string): string {
  const [month] = dateString.split("-");
  const monthName = dates[month];
  return monthName;
}

export const getFormattedTimestamp = (date: Date) => {
  return `Atualizado em ${DateToFormat(date)} às ${getFormattedHour(date)}`;
};

// NOTE: Retorna os meses com inicio em janeiro de 2023 até o mes atual do ano atual
export const referenceMonthMap = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const initialYear = 2023;
  const initialMonth = 1;
  const months = [];

  let year = initialYear;
  let month = initialMonth;

  while (
    year < currentYear ||
    (year === currentYear && month <= currentMonth)
  ) {
    const monthKey = month.toString().padStart(2, "0");
    const monthName = dates[monthKey];

    months.push({
      label: `${monthName} - ${year}`,
      value: `${monthKey}-${year}`,
    });

    month++;
    if (month > 12) {
      month = 1;
      year++;
    }
  }

  return months;
};

// NOTE: Retorna os ultimos 4 meses.
export const referenceMonthLastMap = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const months = [];

  for (let i = 1; i < MONTH_MAX; i++) {
    const month = currentMonth + i;
    const selectedMonth = month <= 12 ? month : month - 12;
    const selectedYear = month <= 12 ? currentYear : currentYear + 1;

    months.push({
      label: `${
        dates[selectedMonth.toString().padStart(2, "0")]
      } - ${selectedYear}`,
      value: `${selectedMonth.toString().padStart(2, "0")}-${selectedYear}`,
    });
  }
  return months;
};

export const isValidDate = (value: Date) => value.toString() !== "Invalid Date";

export function convertToDefaultDate(dateString: string) {
  const date = parseISO(dateString);
  const adjustedDate = add(date, { hours: date.getTimezoneOffset() / 60 });
  const formattedDate = format(adjustedDate, "dd/MM/yyyy");

  return formattedDate;
}

export const getCurrentMonthName = (): string => {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  return months[currentMonthIndex];
};
