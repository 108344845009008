import { CssBaseline, Grid } from "@mui/material";
import React from "react";
// import CardBanner from "../../../components/CardBanner/CardBanner";
import CardSlider from "../../../components/CardSlider/CardSlider";

const BannerTemp = () => {
  return (
    <div style={{ height: 390 }} key="divb">
      <Grid
        container
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <img src={"images/banner.jpg"} key={2} style={{ width: "inherit" }} />
      </Grid>
    </div>
  );
};

const ChristmasTemp = () => {
  return (
    <div style={{ height: 390 }} key="divb">
      <Grid
        container
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <img src={"images/natal.png"} key={2} style={{ width: "inherit" }} />
      </Grid>
    </div>
  );
};

const NewYearTemp = () => {
  return (
    <div style={{ height: 390 }} key="divb">
      <Grid
        container
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <img src={"images/new-year.png"} key={2} style={{ width: "inherit" }} />
      </Grid>
    </div>
  );
};

const getBanner = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const isChristmasSeason = month === 12 && day <= 26;
  const isNewYearSeason =
    (month === 12 && day >= 27) || (month === 1 && day <= 2);

  if (isChristmasSeason) {
    return <ChristmasTemp />;
  } else if (isNewYearSeason) {
    return <NewYearTemp />;
  } else {
    return <BannerTemp />;
  }
};

const itemsCards = [
  // <CardBanner
  //   key={1}
  //   title="A mais nova empresa de refino de petróleo da Amazônia!"
  //   description="Para a Ream, relacionamento é a base de nossas ações: valorizamos o capital humano  traduzido em nossos colaboradores, fornecedores, parceiros e a comunidade à nossa volta, incluindo aí também o relacionamento com o meio ambiente. Movidos pelo DNA Amazônico, contamos com você para ir mais longe."
  // />,
  // eslint-disable-next-line react/jsx-key
  getBanner(),
  // <CardBanner
  //   key={2}
  //   title="Banner de marketing, sustentabilidade, tecnologia e inovação[slide2]"
  //   description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
  // />,
  // <CardBanner
  //   key={3}
  //   title="Banner de marketing, sustentabilidade, tecnologia e inovação[slide3]"
  //   description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
  // />,
  // <CardBanner
  //   key={4}
  //   title="Banner de marketing, sustentabilidade, tecnologia e inovação[slide4]"
  //   description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
  // />,
];

const src: React.FC = () => {
  return (
    <div>
      <CssBaseline />
      <CardSlider componentItems={itemsCards} />
    </div>
  );
};

export default src;
